<template>
  <div class="form-stepper form-stepper-channel_subscription">
    <v-form
      ref="form"
      v-model="valid"
      class="form pa-6"
    >
      <div>
        <p>{{$t('CHANNEL_SUBSCRIPTION.CHANNEL_TEXT')}}</p>
        <v-text-field
          v-model="searchItem"
          hide-details
          outlined
          dense
          clearable
          class="form-stepper-channel_subscription__search-field"
          :placeholder="$t('COMMON.SEARCH')"
          prepend-inner-icon="mdi-magnify"
          @keyup="searchItems"
          @click:clear="searchItems"
        />
      </div>

      <v-text-field
        v-model="channel_id"
        :rules="[$rules.required]"
        style="display:none;"
      />

      <div v-if="channelCreditItems.length > 0" class="mt-4">
        <p class="primary--text mb-0">{{$t('CHANNEL_SUBSCRIPTION.CHANNEL_JOBBOARDS_TITLE')}}</p>
        <p class="grey--text">{{$t('CHANNEL_SUBSCRIPTION.CHANNEL_JOBBOARDS_DESCRIPTION')}}</p>
        <div class="d-flex flex-wrap">
          <template v-for="(item, i) in channelCreditItems">
            <div @click="channel_id = item.id" :key="'chi1'+i" :class="['pa-2 height-10 ma-2 bordered rounded d-flex flex-column justify-center align-center channel-box', channel_id === item.id ? 'bordered-selected elevation-4' : '']" >
              <v-icon v-if="channel_id === item.id" class="corner-icon elevation-2" color="success">mdi-check-circle</v-icon>
              <v-img :src="getImage(item)" max-width="120px" contain />
              <p class="text-nowrap text-ellipsis">{{item.title}}</p>
              <p><v-chip v-for="(method, j) in item.methods" x-small :key="'me'+j">{{method}}</v-chip></p>
            </div>
          </template>
        </div>
      </div>

      <div v-if="channelBudgetItems.length > 0" class="mt-4">
        <p class="primary--text mb-0">{{$t('CHANNEL_SUBSCRIPTION.CHANNEL_AGGREGATORS_TITLE')}}</p>
        <p class="grey--text">{{$t('CHANNEL_SUBSCRIPTION.CHANNEL_AGGREGATORS_DESCRIPTION')}}</p>
        <div class="d-flex flex-wrap">
          <template v-for="(item, i) in channelBudgetItems">
            <div @click="channel_id = item.id" :key="'chi2'+i" :class="['pa-2 height-10 ma-2 bordered rounded d-flex flex-column justify-center align-center channel-box', channel_id === item.id ? 'bordered-selected elevation-4' : '']">
              <v-icon v-if="channel_id === item.id" class="corner-icon elevation-2" color="success">mdi-check-circle</v-icon>
              <v-img :src="getImage(item)" max-width="120px" contain />
              <p class="text-nowrap text-ellipsis">{{item.title}}</p>
              <p><v-chip v-for="(method, j) in item.methods" x-small :key="'me'+j">{{method}}</v-chip></p>
            </div>
          </template>
        </div>
      </div>

      <div v-if="channelDisplayItems.length > 0" class="mt-4">
        <p class="primary--text mb-0">{{$t('CHANNEL_SUBSCRIPTION.CHANNEL_DISPLAY_TITLE')}}</p>
        <p class="grey--text">{{$t('CHANNEL_SUBSCRIPTION.CHANNEL_DISPLAY_DESCRIPTION')}}</p>
        <div class="d-flex flex-wrap">
          <template v-for="(item, i) in channelDisplayItems">
            <div @click="channel_id = item.id" :key="'chi3'+i" :class="['pa-2 height-10 ma-2 bordered rounded d-flex flex-column justify-center align-center channel-box', channel_id === item.id ? 'bordered-selected elevation-4' : '']">
              <v-icon v-if="channel_id === item.id" class="corner-icon elevation-2" color="success">mdi-check-circle</v-icon>
              <v-img :src="getImage(item)" max-width="120px" contain />
              <p class="text-nowrap text-ellipsis">{{item.title}}</p>
              <p><v-chip v-for="(method, j) in item.methods" x-small :key="'me'+j">{{method}}</v-chip></p>
            </div>
          </template>
        </div>
      </div>

      <div v-if="channelsNotFound" :style="{height: '100%'}" class="mt-4 d-flex justify-center align-center">
        <p class="primary--text mb-0">{{$t('CHANNEL_SUBSCRIPTION.CHANNEL_NOT_FOUNDED')}}Channels were not founded</p>
      </div>



    </v-form>

    <v-divider />
    <v-toolbar
      color="transparent"
      flat
      height="70px"
      class="form-footer"
    >
      <v-spacer/>
      <v-btn
        color="primary custom"
        class="mr-4"
        rounded
        depressed
        :disabled="!valid && !loading"
        large
        @click="validate"
      >
        <strong>{{ $t('CONTINUE') }}</strong>
        <v-icon class="ml-4">mdi-arrow-right</v-icon>
      </v-btn>

    </v-toolbar>

  </div>
</template>

<style lang="scss">

  .channel-box {
    background: #fff;
    cursor: pointer;
    height: 160px;
    width: 200px;
    position: relative;
  }

  .corner-icon {
    position: absolute !important;
    right:-10px;
    top: -10px;
    background: #fff;
    border-radius: 100%;
  }

  .form-stepper-channel_subscription__search-field {
    max-width: 50%;

    .theme--light.v-icon {
      z-index: 111;
      color: $color-grey-dark-opacity;
    }
  }
</style>

<script>
export default {
  name: 'FormChannelSubscriptionStepperChannel',
  data: () => ({
    loading: false,
    valid: false,
    channelCreditItems: [],
    channelBudgetItems: [],
    channelDisplayItems: [],
    channelItems: [],
    //
    use_xtramile_account: false,
    channel_id: null,
    searchItem: ''
  }),
  props: {
    values: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    values (n) {
      this.init()
    },
  },
  mounted () {
    this.init()
    this.loadChannelItems()
  },
  computed: {
    channelsNotFound() {
      return !this.channelDisplayItems.length &&
             !this.channelCreditItems.length &&
             !this.channelBudgetItems.length
    }
  },
  methods: {
    init () {
      Object.keys(this.values)
        .forEach((key) => {
          if (typeof this.values[key] !== 'undefined' && typeof this[key] !== 'undefined') {
            this[key] = this.values[key]
          }
        })
    },

    getImage (item, size = "original") {
      const url = `${process.env.VUE_APP_ASSETS}/channels/`
      return `${url + item.controller_name.toLowerCase()  }.png`
    },

    loadChannelItems () {
      this.loading = true
      return this.$services.api_programmatic.channel
        .search({
          where: {status: 1},
          options: {
            order: [['title', 'ASC']],
            limit: 200
          }
        })
        .then(response => {
          if (response.data && response.data.length > 0) {
            response.data.forEach((item, i) => {
              // display
              const displayTypes = ['text', 'audio', 'video', 'image', 'carousel', 'html']
              console.log(item.types)
              for (const type of displayTypes) {
                if (item.types.filter(v => v.indexOf(type) !== -1).length > 0){
                  this.channelDisplayItems.push(item)
                  break
                }
              }
              const offerTypes = ['offer']
              for (const type of offerTypes) {
                if (item.types.filter(v => v.indexOf(type) !== -1).length > 0) {
                  // credit
                  if ('credit'.indexOf(item.methods) !== -1) {
                    this.channelCreditItems.push(item)
                  } else {
                    // aggregator
                    this.channelBudgetItems.push(item)
                  }
                }
              }
            })
            this.channelItems = response.data

          } else {
            this.channelCreditItems = []
            this.channelBudgetItems = []
            this.channelDisplayItems = []
            this.channelItems = []
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    validate () {
      if (!this.$refs.form.validate()) {
        this.$emit('validation', false)
        return
      }

      const data = {}
      data.channel_id = this.channel_id

      this.$emit('childData', data)
      this.$emit('validation', true)
      this.$emit('next')
    },

    searchItems(event) {
      const cloneChannelItems = JSON.parse(JSON.stringify(this.channelItems))
      const channelDisplayItems = []
      const channelCreditItems = []
      const channelBudgetItems = []

      cloneChannelItems
        .filter(i => i.title.toLowerCase().includes(event.target.value.toLowerCase()))
        .forEach((item, i) => {
              // display
              const displayTypes = ['text', 'audio', 'video', 'image', 'carousel', 'html']
              for (const type of displayTypes) {
                if (item.types.filter(v => v.indexOf(type) !== -1).length > 0){
                  channelDisplayItems.push(item)
                  break
                }
              }
              const offerTypes = ['offer']
              for (const type of offerTypes) {
                if (item.types.filter(v => v.indexOf(type) !== -1).length > 0) {
                  // credit
                  if ('credit'.indexOf(item.methods) !== -1) {
                    channelCreditItems.push(item)
                  } else {
                    // aggregator
                    channelBudgetItems.push(item)
                  }
                }
              }
            })

      this.channelDisplayItems = channelDisplayItems
      this.channelCreditItems = channelCreditItems
      this.channelBudgetItems = channelBudgetItems
    },
  },
}
</script>
